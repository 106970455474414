import React from 'react';
import {Link} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from "react-responsive-carousel";
import Image from "./Image";

const Slider = () => (
    <div className="slider container-fluid">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-12 col-lg-6">
                    <div className="slider__slides mb-5 mb-lg-0">
                        <Carousel interval={5000} swipeable={true} showArrows={true} autoPlay={true} showIndicators={false} infiniteLoop={true} showThumbs={false} showStatus={false}>
                            <div className="slide-item">
                                <Image src="../img/honey-01.png" alt="Honey" />
                            </div>
                            <div className="slide-item">
                                <Image src="../img/honey-02.png" alt="Honey" />
                            </div>
                            <div className="slide-item">
                                <Image src="../img/honey-03.png" alt="Honey" />
                            </div>
                        </Carousel>
                    </div>
                </div>
                <div className="col-12 col-lg-6 text-center">
                    <Image src="../img/best-quality.png" alt="Best Quality" />
                    <h1 className="mt-3">Naturally Sweet</h1>
                    <p className="mt-4 mb-4">Best honey you can ever have.</p>
                    <Link to="/about" className="btn btn--primary">Read More</Link>
                </div>
            </div>
        </div>
    </div>
);

export default Slider;
