import React from 'react';
import { Link } from 'react-router-dom';
import Image from "./Image";

const Logo = () => (
    <div className="logo">
        <Link to="/">
            <Image src="../img/logo.svg" alt="Logo" />
        </Link>
    </div>
);

export default Logo;
