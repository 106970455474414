import React from 'react';
import Slider from "../components/Slider";
import Card from "../components/Card";
import Image from "../components/Image";
import IconListItem from "../components/IconListItem";
import Testimonials from "../components/Testimonials";
import Product from "../components/Product";
import Gallery from "../components/Gallery";

const Home = (props) => {
    return(
        <>
            <Slider/>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="cards">
                            <Card
                                color={"#e8eee2"}
                                image={"../img/real-honey.png"}
                                alt={"Honey"}
                                heading={"Wild & Natural"}
                                description={"Natural honey directly from the manufacturer."}/>
                            <Card
                                color={"#ffefe2"}
                                image={"../img/honey-holder.png"}
                                alt={"Honey"}
                                heading={"Flower Power"}
                                description={"Great taste for each flavour."}/>
                            <Card
                                color={"#fff6e7"}
                                image={"../img/bees.png"}
                                alt={"Honey"}
                                heading={"Sweet & Healthy"}
                                description={"One spoon a day for a healthier lifestyle."}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="icon-list container">
                <div className="row flex-column-reverse flex-lg-row align-items-center">
                    <div className="col-12 col-lg-6">
                        <IconListItem image={"../img/icons/icon-1.png"} title={"Pure honey products"} description={"Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis"} />
                        <IconListItem image={"../img/icons/icon-2.png"} title={"Raw Honey with Dipper"} description={"Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis"} />
                        <IconListItem image={"../img/icons/icon-3.png"} title={"Proteins and vitamins"} description={"Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis"} />
                    </div>
                    <div className="col-12 col-lg-6 text-center mb-4 mb-lg-0">
                        <Image src={"../img/flower-honey.jpg"} alt={"Honey"} />
                    </div>
                </div>
            </div>

            <Testimonials />

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="products">
                            <Product url="/products/1" image="../img/products/honey-01.jpg" title="Raw Honey"/>
                            <Product url="/products/2" image="../img/products/honey-02.jpg" title="Manuca Honey"/>
                            <Product url="/products/3" image="../img/products/honey-03.jpg" title="Wildflower Honey"/>
                        </div>
                    </div>
                </div>
            </div>

            <Gallery />

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="clients text-center">
                            <Image src="../img/clients/clients-h1.png" alt="Client"/>
                            <Image src="../img/clients/clients-h2.png" alt="Client"/>
                            <Image src="../img/clients/clients-h3.png" alt="Client"/>
                            <Image src="../img/clients/clients-h1.png" alt="Client"/>
                            <Image src="../img/clients/clients-h2.png" alt="Client"/>
                            <Image src="../img/clients/clients-h3.png" alt="Client"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;