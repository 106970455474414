import React from 'react';
import Product from "./Product";

const RelatedProducts = () => {
    return (
        <>
            <div className="container related-products">
                <div className="row">
                    <div className="col-12">
                        <h3>Related Products</h3>
                    </div>
                    <div className="col-12">
                        <div className="products">
                            <Product url="/products/1" image="../img/products/honey-01.jpg" title="Raw Honey"/>
                            <Product url="/products/2" image="../img/products/honey-02.jpg" title="Manuca Honey"/>
                            <Product url="/products/3" image="../img/products/honey-03.jpg" title="Wildflower Honey"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RelatedProducts;