import React from 'react';
import {Link, Route, Routes} from 'react-router-dom';
import Home from "../pages/Home";
import About from "../pages/About";
import Products from "../pages/Products";
import Contacts from "../pages/Contacts";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const Copyright = () => (

    <div className="copyright-bar">
        © Copyright Qode Interactive. This demo is part of the Bridge theme |
        <Link to="/privacy-policy">Privacy Policy</Link>
    </div>
);

export default Copyright;
