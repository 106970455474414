import React from 'react';
import Hero from "../components/Hero";
import Content from "../components/Content";
import Image from "../components/Image";
import IconListItem from "../components/IconListItem";
import Testimonials from "../components/Testimonials";

const About = () => {
    return(
        <>
            <Hero name="About"/>

            <Content
                row="flex-lg-row"
                title="What we do"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua dolor sit amet em ipsum dolor sit amet. Nunc finibus posuere enim. Proin at nulla enim. Curabitur gravida nequelMei invenire periculis ei, offendit mediocrem ne sea, eos suas elitr everti ea. Per vidit rebum quodsi id."
                src="../img/gallery/gallery-img-2.jpg"
                alt="Honey"
            />

            <div className="container pt-5 pb-5 mb-5">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                        <Image src="../img/gallery/gallery-img-4.jpg" alt="Honey" />
                    </div>
                    <div className="col-12 col-lg-6">
                        <IconListItem image={"../img/icons/icon-1.png"} title={"Pure honey products"} description={"Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis"} />
                        <IconListItem image={"../img/icons/icon-2.png"} title={"Raw Honey with Dipper"} description={"Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis"} />
                        <IconListItem image={"../img/icons/icon-3.png"} title={"Proteins and vitamins"} description={"Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis"} />
                    </div>
                </div>
            </div>

            <Testimonials />

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="clients text-center">
                            <Image src="../img/clients/clients-h1.png" alt="Client"/>
                            <Image src="../img/clients/clients-h2.png" alt="Client"/>
                            <Image src="../img/clients/clients-h3.png" alt="Client"/>
                            <Image src="../img/clients/clients-h1.png" alt="Client"/>
                            <Image src="../img/clients/clients-h2.png" alt="Client"/>
                            <Image src="../img/clients/clients-h3.png" alt="Client"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About;