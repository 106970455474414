import React from 'react';
import Product from "./Product";

const ProductListing = (props) => {
    return (
        <div className="products">
            {
                props.products.map((product) => (
                    <Product
                        key={product.id}
                        url={`/products/${product.id}`}
                        image={product.image}
                        title={product.title} />
                ))
            }
        </div>
    )
}

export default ProductListing;