import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Textbox, Textarea } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';

/**
 * Use https://www.emailjs.com/
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ContactForm = () => {

    const validate = (e) => {
        e.preventDefault();
        let name = document.getElementById('name').value;
        let email = document.getElementById('email').value;
        let message = document.getElementById('message').value;

        if (name == null || name === "", email == null || email === "", message == null || message === "") {
            alert('Please fill all required fields!');
        } else {
            confirmation();
            sendEmail(e);
        }
    }

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');

    const confirmation = () => {
        document.getElementById('contactForm').style.display = 'none';
        document.getElementById('formConfirmation').style.display = 'block';
    }

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_y5n3ij4', 'template_na4ofbn', form.current, 'bIRHjqIhtq9idTYBV')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <>
            <form ref={form} onSubmit={validate} id="contactForm" className="form form--contact flex-column">
                <Textbox
                    attributesInput={{
                        id: 'name',
                        name: 'name',
                        type: 'text',
                        placeholder: 'Your Name *',
                    }}
                    className="form-item"
                    value={name}
                    onChange={(name, e) => {
                        console.log(e);
                    }}
                    onBlur={(e) => {console.log(e)}}
                    validationOption={{
                        name: 'Name',
                        check: true,
                        required: true
                    }}
                />
                <Textbox
                    attributesInput={{
                        id: 'email',
                        name: 'email',
                        type: 'email',
                        placeholder: 'Your Email *',
                    }}
                    className="form-item"
                    value={email}
                    onChange={(email, e) => {
                        console.log(e);
                    }}
                    onBlur={(e) => {console.log(e)}}
                    validationOption={{
                        name: 'Email',
                        check: true,
                        required: true
                    }}
                />
                <Textarea
                    attributesInput={{
                        id: 'message',
                        name: 'message',
                        type: 'text',
                        placeholder: 'Your Message *',
                    }}
                    className="form-item"
                    value={message}
                    onChange={(message, e) => {
                        console.log(e);
                    }}
                    onBlur={(e) => {console.log(e)}}
                    validationOption={{
                        name: 'Message',
                        check: true,
                        required: true
                    }}
                />
                <input
                    type="submit"
                    value="Send"
                    className="btn btn--primary"
                    onClick={validate} />
            </form>

            <div id="formConfirmation" className="form__confirmation alert alert-success mt-4 mb-4">
                Thank you for reaching out. We will contact you as soon as possible.
            </div>
        </>
    );
};

export default ContactForm;
