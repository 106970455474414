import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Textbox } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';

/**
 * Use https://www.emailjs.com/
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Subscribe()
{

    const validate = (e) => {
        e.preventDefault();
        let email = document.getElementById('email').value;

        if (email == null || email === "") {
            alert('Please fill your email address!');
        } else {
            confirmation();
            sendEmail(e);
        }
    }

    const [email, setEmail] = React.useState('');

    const confirmation = () => {
        document.getElementById('subscribeForm').style.display = 'none';
        document.getElementById('subscribeConfirmation').style.display = 'block';
    }

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_y5n3ij4', 'template_krz8gpr', form.current, 'bIRHjqIhtq9idTYBV')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <>
            <form ref={form} onSubmit={validate} id="subscribeForm" className="form form--subscribe flex-column flex-sm-row align-items-center justify-content-center mt-4 mb-4">
                <Textbox
                    attributesInput={{
                        id: 'email',
                        name: 'email',
                        type: 'email',
                        placeholder: 'Your Email',
                    }}
                    className="form-item"
                    value={email}
                    onChange={(email, e) => {
                        console.log(e);
                    }}
                    onBlur={(e) => {console.log(e)}}
                    validationOption={{
                        name: 'Email',
                        check: true,
                        required: true
                    }}
                />
                <input
                    type="submit"
                    value="Subscribe"
                    className="btn btn--primary"
                    onClick={validate} />
            </form>

            <div id="subscribeConfirmation" className="form__confirmation alert alert-success mt-4 mb-4">
                Thank you for reaching out. We will contact you as soon as possible.
            </div>
        </>
    );
}
