import React from 'react';
import Logo from "./Logo";
import Menu from "./Menu";
import Social from "./Social";
import Copyright from "./Copyright";
import Subscribe from "./Subscribe";

const Footer = () => {
    return (
        <div className="footer container-fluid">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 text-center">
                        <Logo />
                        <Menu/>
                        <Subscribe />
                        <Social />
                        <Copyright />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;