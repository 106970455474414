import React from 'react';
import { FaFacebookF } from 'react-icons/fa/index.esm';
import { FaTwitter } from 'react-icons/fa/index.esm';
import { FaLinkedin } from 'react-icons/fa/index.esm';

const Social = () => (
    <div className="social-icons mt-4 mb-4">
        <a href="#" target="_blank">
            <FaFacebookF/>
        </a>
        <a href="#" target="_blank">
            <FaTwitter />
        </a>
        <a href="#" target="_blank">
            <FaLinkedin />
        </a>
    </div>
);

export default Social;
