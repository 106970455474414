import React, {Component} from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import Products from "./pages/Products";
import Contacts from "./pages/Contacts";
import Footer from "./components/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Error from "./pages/Error.js";
import SingleProduct from "./pages/SingleProduct";
import CookieConsent from "react-cookie-consent";
import ScrollToTop from "./components/ScrollToTop";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
        }
    }

    render() {
        return (
            <BrowserRouter>
                <ScrollToTop />
                <Header/>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/contacts" element={<Contacts />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/products/:productId" element={<SingleProduct products={this.state.products} />} />
                    <Route path="*" element={<Error />} />
                </Routes>
                <Footer />
                <CookieConsent
                    location="bottom"
                    buttonText="Great!"
                    cookieName="mainCookies"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "14px" }}
                    contentStyle={{ color: "#FFFFFF" }}
                    expires={150}
                >
                    This website uses cookies to enhance the user experience.
                </CookieConsent>
            </BrowserRouter>
        )
    }
}

export default App;
