import React, { useState } from 'react';
import Image from "./Image";
import 'react-image-lightbox/style.css';
import FsLightbox from 'fslightbox-react';

const Gallery = () => {

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="gallery">
                            <div className="gallery__item" onClick={() => openLightboxOnSlide(1)}>
                                <Image src="../img/gallery/gallery-img-1.jpg" alt="Honey" />
                            </div>
                            <div className="gallery__item" onClick={() => openLightboxOnSlide(2)}>
                                <Image src="../img/gallery/gallery-img-2.jpg" alt="Honey" />
                            </div>
                            <div className="gallery__item" onClick={() => openLightboxOnSlide(3)}>
                                <Image src="../img/gallery/gallery-img-3.jpg" alt="Honey" />
                            </div>
                            <div className="gallery__item" onClick={() => openLightboxOnSlide(4)}>
                                <Image src="../img/gallery/gallery-img-4.jpg" alt="Honey" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FsLightbox
                toggler={lightboxController.toggler}
                sources={[
                    '../img/gallery/gallery-img-1.jpg',
                    '../img/gallery/gallery-img-2.jpg',
                    '../img/gallery/gallery-img-3.jpg',
                    '../img/gallery/gallery-img-4.jpg',
                ]}
                slide={lightboxController.slide}
            />
        </>
    );
}

export default Gallery;