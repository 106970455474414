import React from 'react';

const IconListItem = (props) => (
    <div className="icon-list__item d-flex">
        <div className="icon-list__icon">
            <img src={props.image} alt="Icon"/>
        </div>
        <div className="icon-list__content">
            <h3>{props.title}</h3>
            <p>{props.description}</p>
        </div>
    </div>
);

export default IconListItem;
