import React from 'react';
import Logo from "./Logo";
import Menu from "./Menu";

const Header = () => {
    return (
        <div className="header container-fluid">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-4">
                        <Logo />
                    </div>
                    <div className="col-12 col-md-8">
                        <Menu/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;