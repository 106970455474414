import React from 'react';

const Hero = (props) => (
    <div className="hero container-fluid d-flex align-items-center">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h1 className="text-center">{props.name}</h1>
                </div>
            </div>
        </div>
    </div>
);

export default Hero;
