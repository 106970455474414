import React from 'react';
import Hero from "../components/Hero";
import Image from "../components/Image";
import ContactForm from "../components/ContactForm";

const Contacts = (props) => {
    return(
        <>
            <Hero name="Contacts"/>

            <div className="container mt-5 pt-5 mb-5 pb-5">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                        <Image src="../img/map.jpg" alt="Map" />
                    </div>
                    <div className="col-12 col-lg-6">
                        <h2>Get in touch</h2>
                        <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis</p>
                        <ContactForm />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contacts;