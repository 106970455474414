import React from 'react';
import Image from "./Image";

const Card = (props) => (
    <div className="card d-flex justify-content-center" style={{backgroundColor : `${props.color}`}}>
        <Image src={props.image} alt={props.alt}/>
        <div className="card__content">
            <h3 className="mb-2">{props.heading}</h3>
            <p>{props.description}</p>
        </div>
    </div>
);

export default Card;
