import React from 'react';
import { Link } from 'react-router-dom';
import Image from "./Image";

const Product = (props) => (
    <Link to={props.url} className="product-item text-center">
        <Image src={props.image} alt="Product" />
        <h3 className="mt-3 mb-3">{props.title}</h3>
        <div className="btn btn--simple">Learn More</div>
    </Link>
);

export default Product;