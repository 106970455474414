import React from 'react';
import {Link, useParams} from "react-router-dom";
import data from "../data/products.json";
import Image from "./Image";
import RelatedProducts from "./RelatedProducts";

/**
 * Gets product name
 *
 * @returns {*}
 * @constructor
 */
function GetProductName() {
    let { productId } = useParams();

    let productTitle;

    data.filter(function(item) {
        if ( item.id === parseFloat(productId) ) {
            productTitle = item.title;
        }
    });

    return productTitle;
}

/**
 * Gets product image
 *
 * @returns {*}
 * @constructor
 */
function GetProductImage() {
    let { productId } = useParams();

    let productImage;

    data.filter(function(item) {
        if ( item.id === parseFloat(productId) ) {
            productImage = item.image;
        }
    });

    return <Image src={productImage} alt="Product" /> ;
}

/**
 * Gets product price
 *
 * @returns {*}
 * @constructor
 */
function GetProductPrice() {
    let { productId } = useParams();

    let productPrice;

    data.filter(function(item) {
        if ( item.id === parseFloat(productId) ) {
            productPrice = item.price;
        }
    });

    return productPrice.toFixed(2);
}

/**
 * Gets product description
 *
 * @returns {*}
 * @constructor
 */
function GetProductDescription() {
    let { productId } = useParams();

    let productDescription;

    data.filter(function(item) {
        if ( item.id === parseFloat(productId) ) {
            productDescription = item.description;
        }
    });

    return productDescription;
}

/**
 * Gets product sku
 *
 * @returns {*}
 * @constructor
 */
function GetProductSku() {
    let { productId } = useParams();

    let productSku;

    data.filter(function(item) {
        if ( item.id === parseFloat(productId) ) {
            productSku = item.sku;
        }
    });

    return productSku;
}

/**
 * Gets product weight
 *
 * @returns {*}
 * @constructor
 */
function GetProductWeight() {
    let { productId } = useParams();

    let productWeight;

    data.filter(function(item) {
        if ( item.id === parseFloat(productId) ) {
            productWeight = item.weight;
        }
    });

    return productWeight;
}

/**
 * Gets product dimensions
 *
 * @returns {*}
 * @constructor
 */
function GetProductDimensions() {
    let { productId } = useParams();

    let productDimensions;

    data.filter(function(item) {
        if ( item.id === parseFloat(productId) ) {
            productDimensions = item.dimensionInfo;
        }
    });

    return productDimensions;
}

const ProductSummary = () => {
    return (
        <>
            <div className="container product-summary">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <GetProductImage />
                    </div>
                    <div className="col-12 col-lg-6">
                        <h2><GetProductName /></h2>
                        <div className="product-price mt-3 mb-3">
                            $<GetProductPrice />
                        </div>
                        <p>
                            <GetProductDescription />
                        </p>
                        <ul className="product-meta">
                            <li>
                                SKU: <GetProductSku />
                            </li>
                            <li>
                                Weight: <GetProductWeight /> kg
                            </li>
                            <li>
                                Dimensions: <GetProductDimensions />
                            </li>
                        </ul>

                        <Link to="/contacts" className="btn btn--primary mt-3">Make an inquiry</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductSummary;