import React from 'react';
import Hero from "../components/Hero";

const PrivacyPolicy = (props) => {
    return(
        <>
            <Hero name="Privacy Policy"/>

            <div className="container mt-5 pt-5 mb-5 pb-5">
                <div className="row align-items-center">
                    <div className="col-12 mb-4 mb-lg-0">

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in vulputate lorem. Duis rutrum vel mi non dictum. Maecenas elit turpis, fermentum id maximus nec, posuere quis erat. Duis posuere turpis neque, eu tristique nisi posuere in. Nam pellentesque nisi ac risus condimentum ornare. In tempor blandit velit, et pulvinar nunc pellentesque vel. Morbi rhoncus nisl a laoreet tristique. Donec ultrices nisi leo, vel pulvinar mi maximus vel. Praesent eget rhoncus mauris. In pulvinar libero enim. Sed efficitur cursus dolor eget feugiat. Sed pharetra ultrices dignissim. Duis lacus nibh, dapibus nec cursus nec, rutrum vitae est. Praesent volutpat enim eros, ac ullamcorper neque condimentum quis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In eleifend nec ante ut varius</p>

                        <p>Aliquam vel eros a enim finibus dictum vel eget enim. Sed dapibus non felis sit amet laoreet. Donec egestas eu arcu et facilisis. Curabitur at condimentum urna. Curabitur porttitor sagittis rhoncus. Sed leo tortor, ultrices ut efficitur et, luctus a justo. Suspendisse potenti. Suspendisse quis orci metus</p>

                        <p>Quisque aliquam condimentum imperdiet. Sed finibus dui a sodales molestie. Morbi laoreet vestibulum commodo. Duis vitae volutpat ligula. Nulla facilisi. Fusce vitae est lorem. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nulla facilisi. Sed et magna et enim suscipit aliquet tristique eget velit. Mauris feugiat sit amet purus sit amet venenatis</p>

                        <p>Maecenas sagittis, ex quis malesuada placerat, massa tellus feugiat massa, vel tempor dui tortor a justo. Fusce urna elit, pulvinar vitae augue ut, pellentesque ultrices felis. Vivamus auctor massa vel quam sollicitudin, accumsan eleifend urna imperdiet. Suspendisse pretium cursus posuere. Quisque sagittis vitae felis et efficitur. Duis accumsan finibus nisi nec sagittis. Integer aliquet fermentum enim, quis bibendum eros molestie a</p>

                        <p>Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Maecenas luctus a odio a euismod. Duis sapien purus, pretium sit amet tristique eu, porta ut tortor. Maecenas vitae ligula eleifend, aliquam nunc in, finibus lorem. Pellentesque fringilla felis eu bibendum sodales. Vestibulum vestibulum, nunc quis mollis gravida, ipsum nunc feugiat erat, et ultrices quam urna quis orci. Maecenas consequat, massa vel tristique pulvinar, felis risus iaculis magna, at cursus urna enim eu neque. Phasellus ut tempus nibh. Proin pretium vel ipsum ac suscipit. In placerat lorem luctus, hendrerit neque non, condimentum ante. Curabitur scelerisque tortor vel sagittis porttitor. Etiam varius pharetra diam eget gravida. Donec in mi mattis, ullamcorper augue fermentum, ullamcorper est. Nam sollicitudin magna odio, et aliquam turpis placerat eu. Fusce id diam hendrerit, ornare sapien a, porta odio</p>

                        <p>Suspendisse sit amet ullamcorper neque. Aenean vel sapien placerat, sodales eros eget, lacinia lorem. Vivamus vulputate pellentesque arcu at ultricies. Quisque a arcu eros. Donec consequat, mauris vel sollicitudin sodales, elit erat aliquam tellus, vitae dapibus libero mauris a erat. Nam neque tortor, feugiat non lacinia at, consequat commodo tortor. Pellentesque congue quam ligula, eu blandit ligula pharetra et. Integer venenatis sit amet sem non sagittis. Curabitur eu metus in tellus porttitor pellentesque gravida bibendum odio. Etiam efficitur lectus et elit congue, id scelerisque arcu mattis. Pellentesque aliquam dignissim nunc vel semper</p>

                        <p>Nam ullamcorper tellus quam, non dictum tortor tempor iaculis. Etiam sed tempus neque, ac dignissim magna. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec non nisi tristique, pharetra mi quis, maximus mauris. Nulla velit eros, sagittis sit amet justo nec, convallis feugiat augue. Curabitur dictum tortor nec ex tincidunt aliquam. Duis sit amet porta odio. Ut ut felis eros</p>

                        <p>Donec venenatis vel purus egestas gravida. Duis ante dui, consectetur non turpis eu, ultrices pulvinar ante. Integer ac magna ac lacus sollicitudin semper. Cras sit amet congue sapien, quis condimentum tellus. Aliquam quis hendrerit leo, a maximus orci. Duis commodo libero sit amet ante tincidunt vehicula. Aliquam ac sem bibendum tellus consectetur suscipit. Etiam justo orci, hendrerit ultrices magna sed, semper iaculis lectus. Fusce at ex et ante porta faucibus vitae quis odio. Proin fermentum justo id auctor laoreet. Curabitur id enim vel elit condimentum volutpat. Nam nec diam fringilla, maximus mauris a, rutrum turpis. Sed hendrerit lobortis felis sed vulputate. Donec consectetur sem non diam posuere, eget volutpat est pulvinar</p>

                        <p>Nullam convallis in tellus quis tempus. Nam ac magna eget sem rutrum interdum ut vitae tortor. Quisque eu pharetra metus, eu elementum lorem. Mauris cursus ante auctor lectus consequat, in tempor nisi consequat. Vestibulum nisi ligula, ultricies fermentum vehicula vitae, porta et tellus. Pellentesque consectetur lacus metus, et condimentum nunc faucibus non. Ut sodales accumsan quam, vitae tempus turpis sagittis eget</p>

                        <p>Phasellus convallis eget nibh eget tempor. Duis volutpat dapibus leo ac tincidunt. Duis ultricies placerat auctor. Sed sit amet purus sit amet eros imperdiet pulvinar in eget elit. Aliquam pulvinar maximus turpis et ultricies. Integer finibus ante erat, ut viverra sem aliquet vitae. Aenean quis lectus eu nibh egestas mollis vitae eu justo. Aliquam sagittis commodo lectus in mattis. Quisque a diam consectetur, ultricies est sollicitudin, volutpat nunc. Phasellus vestibulum feugiat felis non semper. In eleifend quam quis nulla ultricies sollicitudin quis non nulla. Mauris felis ex, viverra sed risus vel, porta scelerisque sem</p>

                        <p>Morbi orci nibh, tincidunt vitae sagittis sed, posuere a risus. Cras venenatis in felis quis semper. Aliquam at sapien dignissim, sodales quam sed, vulputate diam. In non leo viverra, luctus diam porta, consectetur elit. Integer quis felis turpis. Donec gravida porttitor magna, a feugiat ex convallis ac. Cras a bibendum libero. Vivamus sed ipsum tortor. Aliquam erat volutpat. Praesent aliquam sem porttitor dolor imperdiet, vitae cursus arcu iaculis. Curabitur imperdiet sem lacus, ac facilisis odio pellentesque eu. Sed volutpat quam erat, pharetra pulvinar risus facilisis a. Nunc feugiat feugiat condimentum. Pellentesque vitae luctus ante, sed tincidunt sem. Curabitur rhoncus est augue, quis posuere felis porta non</p>

                        <p>Quisque malesuada laoreet ligula, vitae placerat est commodo hendrerit. Donec gravida nisl at nibh hendrerit, in tempus augue rhoncus. Morbi nec purus vitae tortor mattis egestas eu non justo. Praesent at dui ex. Vivamus interdum iaculis dictum. Sed tempor quam id velit porta luctus. Sed vehicula tortor eu mauris tincidunt, vitae placerat diam ultrices. Nullam nec lorem leo. Pellentesque dictum massa ac arcu scelerisque lacinia. Duis ac interdum enim, at congue lorem. Pellentesque imperdiet turpis at ornare imperdiet. Phasellus bibendum mollis venenatis. Curabitur sollicitudin nulla lorem, ut rhoncus erat malesuada vel</p>

                        <p>Mauris vel lobortis mi. Sed mi velit, lacinia eu est vitae, laoreet sagittis mi. Sed id purus tellus. Cras justo nulla, ultrices id volutpat at, varius id justo. Vestibulum ut scelerisque justo. Donec eget justo est. Aliquam porta lectus vehicula enim convallis, nec vestibulum lacus molestie. Sed lectus ex, tincidunt vitae rhoncus quis, dignissim vel libero. Vestibulum sit amet mi placerat, tincidunt nulla sit amet, ornare sapien. Suspendisse viverra mollis tortor, eu cursus libero rutrum vel. Donec rhoncus fringilla odio. Sed a pellentesque ligula, id iaculis metus. Pellentesque tristique mollis orci, id venenatis magna. Curabitur accumsan non leo id varius. Cras vitae sagittis purus, id ornare erat</p>

                        <p>Nam maximus orci euismod nisl volutpat, at consequat arcu bibendum. Nunc congue lacus commodo odio suscipit dictum. In non libero eget libero viverra tempus at eget risus. Fusce non sapien ut neque aliquam semper et vel magna. Aliquam vehicula posuere sapien, a fringilla sapien sodales et. Aliquam maximus, urna nec elementum vulputate, quam nisl auctor arcu, vel bibendum nunc risus eu libero. Nulla nec arcu at sem dictum mattis sed et nisl. Pellentesque et purus eros. Vestibulum ut placerat orci. Integer molestie risus vitae eleifend iaculis. Aenean a neque pharetra, cursus magna egestas, laoreet eros</p>

                            <p>Vivamus varius lobortis dignissim. Sed a lobortis enim. Fusce ac dignissim turpis. In hac habitasse platea dictumst. Mauris et nulla leo. Praesent a hendrerit erat. Vestibulum iaculis efficitur turpis. Suspendisse scelerisque venenatis vulputate. Duis suscipit odio at convallis ullamcorper. Fusce in tellus a quam fermentum suscipit. Etiam finibus facilisis tincidunt.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy;