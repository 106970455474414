import React from 'react';
import { Link } from 'react-router-dom';

const Menu = () => (
    <div id="menu" className="main-menu">
        <div className="menu-wrapper">
            <Link to="/" className="menu-item">Home</Link>
            <Link to="/about" className="menu-item">About</Link>
            <Link to="/products" className="menu-item">Products</Link>
            <Link to="/contacts" className="menu-item">Contacts</Link>
        </div>
    </div>
);

export default Menu;
