import React from 'react';
import Image from "./Image";

const Content = (props) => (
    <div className="container mt-5 mb-5">
        <div className={`row flex-column-reverse align-items-center ${props.row}`}>
            <div className="col-12 col-lg-6">
                <h2 className="mb-2">{props.title}</h2>
                <p>{props.description}</p>
            </div>
            <div className="col-12 col-lg-6 mb-3 mb-lg-0 mt-3 mt-lg-0">
                <Image src={props.src} className="img-fluid" alt={props.alt}/>
            </div>
        </div>
    </div>
);

export default Content;
