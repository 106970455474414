import React from 'react';

const TestimonialItem = (props) => (
    <div className="testimonial text-center">
        <div className="testimonial__content">
            {props.content}
        </div>
        <div className="testimonial__author">
            {props.author}
        </div>
    </div>
);

export default TestimonialItem;
