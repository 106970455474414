import React, { Component } from 'react';
import Hero from "../components/Hero";
import data from '../data/products.json';
import ProductListing from "../components/ProductListing";

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: [],
        }
    }

    componentWillMount() {
        this.setState({
            products: data,
        });
    }

    render() {
        return(
            <>
                <Hero name="Products"/>

                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ProductListing products={this.state.products} />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Products;