import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from "react-responsive-carousel";
import TestimonialItem from "./TestimonialItem";

const Testimonials = () => (
    <div className="testimonials container-fluid">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-12 mb-5">
                    <h2 className="text-center">All that kind words</h2>
                </div>
                <div className="col-12">
                    <Carousel interval={5000} swipeable={true} showArrows={false} autoPlay={true} showIndicators={true} infiniteLoop={true} showThumbs={false} showStatus={false}>
                        <TestimonialItem author={"Jessica Bertram"} content={"Mei ei fabellas sensibus gloriatur, cu sea integre deterruisset interpretaris. Iusto legimus suscipit vix ei, dico porro minim eam eu. Mutat accusata accusamus quo ex, ei errem oportere mediocrem mea, eam cu omnes fuisset accusam. Vim ei vide equidem constituto."} />
                        <TestimonialItem author={"Jacques Braden"} content={"Mei ei fabellas sensibus gloriatur, cu sea integre deterruisset interpretaris. Iusto legimus suscipit vix ei, dico porro minim eam eu. Mutat accusata accusamus quo ex, ei errem oportere mediocrem mea, eam cu omnes fuisset accusam. Vim ei vide equidem constituto."} />
                        <TestimonialItem author={"Stanley Hudson"} content={"Mei ei fabellas sensibus gloriatur, cu sea integre deterruisset interpretaris. Iusto legimus suscipit vix ei, dico porro minim eam eu. Mutat accusata accusamus quo ex, ei errem oportere mediocrem mea, eam cu omnes fuisset accusam. Vim ei vide equidem constituto."} />
                    </Carousel>
                </div>
            </div>
        </div>
    </div>
);

export default Testimonials;
