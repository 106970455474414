import React from 'react';
import Hero from "../components/Hero";
import ProductSummary from "../components/ProductSummary";
import RelatedProducts from "../components/RelatedProducts";

const SingleProduct = () => {
    return (
        <>
            <Hero name="Catalogue"/>

            <ProductSummary />

            <RelatedProducts />
        </>
    )
}

export default SingleProduct;